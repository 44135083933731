<template>
  <div>
    <v-app-bar app color="#f5f5f5" dense dark absolute   style="width: auto"
      small>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title style="color:red" v-text="$t($route.name)"></v-toolbar-title>

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab icon v-bind="attrs" v-on="on" @click="reColor()"  width="30" height="30" style="margin:15px;">
            <svg width="30" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 16.5 17.1" style="enable-background:new 0 0 16.5 17.1;" xml:space="preserve">
            <path class="st0" d="M16.5,11.8c-1.8,4.4-6.9,6.4-11.2,4.6S-1.2,9.5,0.7,5.2C1.9,2.3,4.5,0.4,7.5,0C7.7,0,7.9,0.1,8,0.3
              C8.1,0.5,8.1,0.7,8,0.9C6.7,2.1,6,3.8,6,5.5c0,3.6,3,6.5,6.6,6.5c1.1,0,2.1-0.3,3.1-0.8c0.2-0.1,0.4-0.1,0.6,0.1
              C16.5,11.4,16.5,11.6,16.5,11.8z"/>
            </svg>
          </v-btn>
        </template>
        <span>{{ $t("AppBar.darkMode") }}</span>
        
      </v-tooltip>


      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab icon @click="langChang()" v-bind="attrs" v-on="on" width="30" height="30" style="margin:15px;">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 255.3 255.1" style="enable-background:new 0 0 255.3 255.1;" xml:space="preserve">
            <g transform="translate(0.000000,392.000000) scale(0.100000,-0.100000)">
              <path class="st0" d="M1135.5,3916.7c-298-38-550-162-761-373c-186-185-299-393-355-651c-26-118-26-380,0-500
                c96-447,411-808,836-958c149-52,237-66,420-66s271,14,420,66c534,189,884,708,856,1268c-16,334-148,624-390,858
                c-177,171-375,278-616,333C1462.5,3912.7,1214.5,3926.7,1135.5,3916.7z M1197.5,3725.7c2-16,2-112,0-215l-3-188h-164
                c-114,0-165,3-165,11c0,21,78,177,118,235c46,67,123,144,167,167C1191.5,3757.7,1193.5,3756.7,1197.5,3725.7z M1404.5,3733.7
                c69-36,164-151,225-273c31-62,56-118,56-125c0-10-35-13-167-13h-168l3,215c1,118,5,215,8,215S1383.5,3743.7,1404.5,3733.7z
                M840.5,3627.7c-41-62-135-267-135-294c0-8-50-11-166-11h-166l31,38c53,68,171,173,256,228c72,47,215,117,223,110
                C884.5,3696.7,865.5,3664.7,840.5,3627.7z M1770.5,3657.7c126-60,293-191,376-297l31-38h-164h-163l-19,53c-27,76-90,204-130,265
                c-20,30-36,55-36,58C1665.5,3703.7,1705.5,3687.7,1770.5,3657.7z M651.5,3140.7c-18-91-37-227-44-318l-7-102l-225,2
                c-123,0-225,2-227,4c-1,1,4,43,12,92c15,96,48,205,89,297l25,57h192h192L651.5,3140.7z M1198.5,2946.7l-1-225l-221-2l-221-2v52
                c0,84,33,317,56,391c2,9,51,12,196,12h193L1198.5,2946.7z M1752.5,3109.7c18-81,43-277,43-340v-52l-221,5l-221,5l1,215
                c0,118,1,218,1,223c0,4,86,6,192,5l191-3L1752.5,3109.7z M2322.5,3062.7c41-103,68-212,75-299l3-44l-37,1c-21,0-123,1-226,1l-189,1
                l-6,103c-4,56-16,155-27,221c-11,65-20,120-20,122c0,3,86,3,192,2l192-3L2322.5,3062.7z M1191.5,2560.7c1-2,4-101,6-221
                c3-187,1-217-12-218c-11,0-290,0-366,1c-18,0-64,281-64,392v48l198,2C1143.5,2567.7,1184.5,2566.7,1191.5,2560.7z M2400.5,2560.7
                c5-5-15-130-31-198c-9-36-32-104-51-152l-35-87l-194-3c-107-2-194-2-194-1c0,0,9,54,20,119c11,66,23,165,27,221c5,94,8,102,27,105
                C1997.5,2568.7,2396.5,2565.7,2400.5,2560.7z M608.5,2460.7c6-92,29-256,44-315l5-23h-195h-195l-40,103c-36,91-60,188-76,300l-5,37
                h228h228L608.5,2460.7z M1795.5,2513.7c0-61-25-258-43-337c-12-50-17-58-35-57c-12,0-98,1-192,2l-170,1v220v220h220h220V2513.7z
                M686.5,1962.7c9,0,22-20,33-52c29-81,91-208,131-267c19-30,34-55,33-57c-6-5-124,50-187,88c-99,60-164,112-248,202l-76,81l39,5
                c21,3,89,4,149,3C621.5,1963.7,677.5,1962.7,686.5,1962.7z M1196.5,1773.7c1-102,2-198,3-213c3-33-3-34-52-9
                c-44,23-126,106-172,174c-33,51-110,207-110,226c0,10,286,21,318,12C1192.5,1961.7,1195.5,1913.7,1196.5,1773.7z M1682.5,1958.7
                c8-8-41-121-85-197c-46-78-144-184-193-210c-50-25-55-24-53,9c1,15,3,111,3,214l1,187l138,3
                C1644.5,1967.7,1674.5,1966.7,1682.5,1958.7z M2172.5,1958.7c12-12-197-207-279-260c-75-49-218-119-226-112c-1,2,14,27,33,57
                c40,59,102,186,130,266c14,38,24,53,39,55C1903.5,1969.7,2167.5,1964.7,2172.5,1958.7z"/>
            </g>
            </svg>
          </v-btn>
        </template>
        <span>{{ $t("AppBar.lang") }}</span>
      </v-tooltip> -->
      <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
        <template v-slot:activator="{ attrs, on }">
          <v-btn min-width="0" icon v-bind="attrs" v-on="on"  width="30" height="30" style="margin:15px;">
            <svg width="30" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 28 20" style="enable-background:new 0 0 28 20;" xml:space="preserve">
            <path class="st0" d="M2,4h24c1.1,0,2-0.9,2-2s-0.9-2-2-2H2C0.9,0,0,0.9,0,2S0.9,4,2,4z M26,8H2c-1.1,0-2,0.9-2,2s0.9,2,2,2h24
              c1.1,0,2-0.9,2-2S27.1,8,26,8z M26,16H2c-1.1,0-2,0.9-2,2s0.9,2,2,2h24c1.1,0,2-0.9,2-2S27.1,16,26,16z"/>
            </svg>
          </v-btn>
        </template>
        <v-list dense>

          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item v-for="(item, i) in profile" :key="i" :to="item.to">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title   v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

      </v-menu>


    </v-app-bar>
  </div>
</template>


<style >
.navbar-brand img
{
  max-height:74px !important;
}
  
</style>
<script>
import Vue from 'vue'
  export default {
    data() {
      return {

        selectedItem: 1,
    
        profile: [
          
          // {
          //   text: this.$t('AppBar.profile'),
          //    icon: 'mdi-account',
          //   to: {
          //     path: "Profile",
          //   },
          // },
          // {
          //   text: this.$t('AppBar.Settings'),
          //     icon: 'mdi-cog-outline',
          //   to: {
          //     path: "setting",
          //   },
          // },


          {
            text: this.$t('AppBar.sign_out'),
            icon: 'mdi-logout',
            to: {
              name: "Login",
            },
          },
        ],
      }


    },



    methods: {
      reColor() {
        localStorage.setItem("darkMode", !this.$vuetify.theme.dark);
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      },

      langChang() {

          let authenticate = Promise.resolve({ role: "admin" });
        authenticate.then(user => { Vue.prototype.$user.set(user);})
        this.$i18n.locale = this.$i18n.locale == "ar" ? "en" : "ar";
        localStorage.setItem("lang", this.$i18n.locale);
        this.$store.dispatch("UpdateLang", this.$i18n.locale);
        this.$vuetify.rtl = localStorage.getItem("lang") == "ar" ? true : false;
     
        //    moment.locale(this.$i18n.locale == "en" ? "en" : "ar-kw");
      }


    },

  }
</script>